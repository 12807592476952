@keyframes lds-ripple-5faaa0 {
  0% {
    opacity: 1;
    width: 0;
    height: 0;
    top: 28px;
    left: 28px;
  }

  100% {
    opacity: 0;
    width: 58px;
    height: 58px;
    top: -1px;
    left: -1px;
  }
}

.fa-icon {
  fill: currentColor;
  vertical-align: -.125em;
  display: inline-block;
  overflow: visible;
}

.fa-icon > g {
  transform-origin: 50%;
}

footer[data-v-2f9f2a] {
  z-index: 9999;
  width: 100%;
  max-width: 800px;
  position: absolute;
  bottom: 0;
}

footer > div[data-v-2f9f2a] {
  color: #fff;
  background: #333;
  border-radius: .5rem;
  padding: .75em 1em;
}

button[data-v-2f9f2a] {
  text-transform: uppercase;
  color: red;
  background: none;
  border: none;
  font-weight: bold;
}

#app[data-v-88b374] {
  min-width: 320px;
  max-width: 800px;
  height: 100vh;
  margin: 0 auto;
}

.main[data-v-88b374] {
  background: #fff;
  border-top: 1px solid #f9f8fa;
  border-radius: 12px;
  position: relative;
}

.day[data-v-1003e8] {
  color: #333;
  border-bottom: 1px solid #eee;
  padding: .75em;
  line-height: 32px;
  text-decoration: none;
}

.day[data-v-1003e8]:hover {
  color: #333;
  text-decoration: none;
}

.day.disabled[data-v-1003e8], .day.empty .counters[data-v-1003e8] {
  color: #aaa;
}

.day.disabled svg.canceled[data-v-1003e8], .day.empty .counters svg.canceled[data-v-1003e8] {
  color: red;
  vertical-align: middle;
  min-width: 26px;
  max-width: 26px;
  min-height: 26px;
  max-height: 26px;
}

.day .additional-notes[data-v-1003e8] {
  vertical-align: top;
  height: 32px;
}

.day .weekday[data-v-1003e8] {
  font-variant: small-caps;
  text-align: center;
  border-radius: 100%;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  font-size: .9rem;
  display: inline-block;
}

.day.end-of-week[data-v-1003e8] {
  border-bottom: 2px solid #bbb;
}

.day .note[data-v-1003e8], .day time[data-v-1003e8] {
  color: #aaa;
}

[data-v-1003e8] .unhappy {
  color: red;
}

.lds-ripple[data-v-5faaa0] {
  width: 56px;
  height: 56px;
  display: block;
  position: relative;
}

.lds-ripple div[data-v-5faaa0] {
  opacity: 1;
  border-style: solid;
  border-width: 4px;
  border-radius: 50%;
  animation: 1s cubic-bezier(0, .2, .8, 1) infinite lds-ripple-5faaa0;
  position: absolute;
}

.lds-ripple div[data-v-5faaa0]:nth-child(2) {
  animation-delay: -.5s;
}

main[data-v-861cf3] {
  flex: 1;
}

.person[data-v-104ba5] {
  font-size: 1em;
}

.person .icon[data-v-104ba5] {
  color: #000;
  text-align: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.person .data[data-v-104ba5] {
  flex: 3;
}

.person time[data-v-104ba5] {
  flex: 1;
  font-size: .875rem;
}

.person .name[data-v-104ba5], .person time[data-v-104ba5] {
  color: #444;
}

.person .note[data-v-104ba5] {
  color: #888;
  font-size: .875rem;
}

.person .note-unhappy[data-v-104ba5] {
  color: red;
}

.person[data-v-104ba5]:hover {
  cursor: default;
  text-decoration: none;
}

.person:hover .note[data-v-104ba5] {
  color: #444;
}

.person.shadow[data-v-104ba5]:hover {
  z-index: 1;
  cursor: pointer;
  box-shadow: 0 1px 2px #84898d4d, 0 1px 3px 1px #3c404326;
}

.order-0[data-v-104ba5] {
  order: 0;
}

.order-1[data-v-104ba5] {
  order: 1;
}

.order-2[data-v-104ba5] {
  order: 2;
}

.order-3[data-v-104ba5] {
  order: 3;
}

.order-4[data-v-104ba5] {
  order: 4;
}

.order-5[data-v-104ba5] {
  order: 5;
}

.order-6[data-v-104ba5] {
  order: 6;
}

.order-7[data-v-104ba5] {
  order: 7;
}

.order-8[data-v-104ba5] {
  order: 8;
}

.order-9[data-v-104ba5] {
  order: 9;
}

.order-10[data-v-104ba5] {
  order: 10;
}

footer[data-v-aa8c7d] {
  border-top: 1px solid #d3d3d3;
}

.btn[data-v-a7a6cc] {
  box-shadow: 0 6px 6px -6px #ccc;
}

.notes[data-v-130e95] {
  border-bottom: 1px solid #eee;
  font-size: 1em;
}

.notes[data-v-130e95] h1 {
  font-size: 1.2em;
}

.notes.day-canceled[data-v-130e95] h1 {
  color: red;
}

.read-more[data-v-130e95] {
  cursor: pointer;
}

.empty[data-v-433bfc] {
  color: #666;
  padding: .5rem 1rem;
}

.day[data-v-1abea7] {
  touch-action: pan-x pan-y;
}

footer[data-v-1abea7] {
  z-index: 1000;
  width: 100%;
  max-width: 800px;
  position: fixed;
  bottom: 0;
}

.button-placeholder[data-v-1abea7] {
  height: 4em;
}

.input-group-append.unhappy .btn[data-v-3914e5] {
  color: #fff;
  background: #df0a14;
}

input[type="submit"][data-v-bf519c] {
  min-width: 6em;
}

a[data-v-e06038] {
  color: #777;
  padding: 4rem 2rem;
  display: none;
  position: fixed;
  top: 32%;
}

a.left[data-v-e06038] {
  border-right: 1px solid #e4e5e6;
  border-radius: 0 1000em 1000em 0;
  left: 0;
}

a.right[data-v-e06038] {
  border-left: 1px solid #e4e5e6;
  border-radius: 1000em 0 0 1000em;
  right: 0;
}

nav[data-v-7d4452] {
  color: #444;
  height: 3.5em;
  min-height: 3.5em;
}

[data-v-7d4452] .nav-btn {
  color: #777;
  z-index: 9999;
  cursor: pointer;
  background: none;
  border: none;
  display: block;
}

[data-v-7d4452] .nav-btn:focus {
  outline: none;
}

[data-v-7d4452] .nav-btn:hover {
  color: #555;
}

[data-v-7d4452] .nav-btn.nav-back-btn {
  color: #333;
}

time[data-v-d8a458] {
  font-weight: bold;
}

.nav-btn-group[data-v-665306] {
  flex-grow: 1;
}

.nav-btn-group[data-v-665306] .nav-btn {
  margin-left: 1.5rem;
}

.nav-btn-group[data-v-665306] .nav-btn:not(:last-child) {
  margin-left: 0;
}

.logo[data-v-a1105d] {
  align-items: center;
  overflow: hidden;
}

.logo img[data-v-a1105d] {
  max-width: 10000%;
  height: 24px;
}

.logo-fader[data-v-a1105d] {
  background: #f8f9fa;
  width: .5rem;
  box-shadow: -.5rem 0 .5rem .5rem #f8f9fa;
}
/*# sourceMappingURL=index.910e0103.css.map */
